/** @format */

import React, {useState, useEffect} from 'react';
// Custom Components
import Item from '../../components/item';
import ItemMobile from '../../components/mobile/item';
// React Router
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import meta from '../../components/shop/meta';
// A custom hook that builds on useLocation to parse
// the query string for you.
// From: https://reactrouter.com/web/example/query-parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
var prevItem;
const ItemPage = props => {
  const {cartUtils, userData, isMobile} = props;
  const {material, options} = useParams();
  const history = useHistory();
  const query = useQuery();
  const [relatedItems, setRelatedItems] = useState([]);
  const [validOptions, setValidOptions] = useState({});
  const [selectedItem, setSelectedItem] = useState(undefined);

  const getDefaultOptions = () => {
    let returnObj = {material: material};

    if (options) {
      const splitOpts = splitString(options);
      returnObj.color = splitOpts[0];
      returnObj.diameter = splitOpts[1];
      returnObj.weight = splitOpts[2];
    } else {
      returnObj.color = query.get('color')
        ? decodeURIComponent(query.get('color'))
        : 'black';
      returnObj.diameter = query.get('diameter') || '1.75mm';
      returnObj.weight = query.get('weight') || '1kg';
    }

    return returnObj;
  };

  const getSelected = (data, defaultOptions) => {
    let selected = data.find(i => {
      for (const key in i.tags) {
        if (key !== 'colorName' && key !== 'showOnHome') {
          if (
            !defaultOptions[key] ||
            defaultOptions[key].toLowerCase() !== i.tags[key].toLowerCase()
          ) {
            return false;
          }
        }
      }
      return true;
    });
    if (process.env.NODE_ENV === 'production') {
      try{
        if(prevItem !== selected.id) {
          prevItem = selected.id;
          window.gtag("event", "view_item", {
            value: Number(selected.price)/100,
            currency: "USD",
            items:[{
              item_id: selected.id,
              item_name:selected.name,
              item_category: selected.tags.material
            }]
          });
        }
      } catch(e) {}
    }
    return selected;
  };

  useEffect(() => {
    const defaultOptions = getDefaultOptions();

    if (relatedItems[0] === undefined) {
      fetch(`/api/items/tags?key=material&value=${material}`)
        .then(resp => resp.json())
        .then(async data => {
          let filteredData = [];
          filteredData = data.filter(item => item.tags.weight !== '200g');
          if (defaultOptions.weight === '5kg') {
            filteredData = data.filter(item => item.tags.weight === '5kg');
          }
          if (query.get('discord') !== 'true') {
            filteredData = filteredData.filter(
              item => item.data.discordOnly !== true
            );
          }

          filteredData = filteredData.filter(item => item.active !== true);

          filteredData.sort((a, b) => {
            return a.displayIndex - b.displayIndex;
          });

          let sides = false;
          filteredData.forEach(item => {
            if (item.sides && item.sides.length > 0) {
              sides = true;
            }
          });

          if (sides === true) {
            filteredData = await getSidesInventory(filteredData);
          }

          // setting relatedItems
          setRelatedItems(filteredData);
          // setting validOptions
          let validOpts = {
            color: [],
            diameter: [],
            weight: [],
            colorName: [],
          };
          for (const item of filteredData) {
            for (const optName in item.tags) {
              // Filtering out options
              if (optName !== 'material' && optName !== 'weight') {
                let optVal = item.tags[optName];
                if (!validOpts[optName]) {
                  validOpts[optName] = [];
                }
                if (!validOpts[optName].includes(optVal)) {
                  validOpts[optName].push(optVal);
                }
              }
            }
          }
          setValidOptions(validOpts);
          let selected = getSelected(filteredData, defaultOptions);
          if (selected) {
            setSelectedItem(selected);
          } else {
            history.push('/404');
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  const getSidesInventory = async data => {
    let invMap = {};
    for (let item of data) {
      let {sides} = item;
      for (let side of sides) {
        if (!invMap[side]) {
          invMap[side] = (
            await fetch(`/api/item/${side}`).then(resp => resp.json())
          ).qoh;
        }
      }
    }

    data.forEach(item => {
      item.sides.forEach(side => {
        if (invMap[side] < item.qoh) {
          item.qoh = invMap[side];
        }
      });
    });
    return data;
  };

  useEffect(() => {
    const defaultOptions = getDefaultOptions();
    let selected = getSelected(relatedItems, defaultOptions);
    if (!selectedItem || selected?.id !== selectedItem.id) {
      setSelectedItem(selected);
    }
    // eslint-disable-next-line
  }, [relatedItems, query]);

  return (
    <>
      {meta[options]?
      <Helmet>
        <title>
          {meta[options].title}
        </title>
        <meta name="description" content={meta[options].desc} />
      </Helmet>
      :<Helmet>
        <title>
          {Object.keys(getDefaultOptions())
            .map(key => getDefaultOptions()[key])
            .join(' ')}{' '}
          | High-Quality 3D Printing Filament | PrintBed
        </title>
      </Helmet>}
      {relatedItems[0] !== undefined && selectedItem !== undefined ? (
        isMobile ? (
          <ItemMobile
            options={validOptions}
            defaultOptions={selectedItem.tags}
            itemId={selectedItem.id}
            selectedItem={selectedItem}
            material={material}
            relatedItems={relatedItems}
            history={history}
            cartUtils={cartUtils}
            userData={userData}
          />
        ) : (
          <Item
            options={validOptions}
            defaultOptions={selectedItem.tags}
            itemId={selectedItem.id}
            selectedItem={selectedItem}
            material={material}
            relatedItems={relatedItems}
            history={history}
            cartUtils={cartUtils}
            userData={userData}
          />
        )
      ) : (
        <div style={{flexGrow: 1}}></div>
      )}
    </>
  );
};

const splitString = (input) => {
  // Find the position of the first and last hyphens
  const firstHyphenIndex = input.indexOf('-');
  const lastHyphenIndex = input.lastIndexOf('-');

  // If there are less than two hyphens, return the input as it cannot be split into three parts
  if (firstHyphenIndex === -1 || lastHyphenIndex === -1 || firstHyphenIndex === lastHyphenIndex) {
      return [input];
  }

  // Extract the three parts based on the positions of the first and last hyphens
  const part1 = input.substring(0, firstHyphenIndex);
  const part2 = input.substring(firstHyphenIndex + 1, lastHyphenIndex);
  const part3 = input.substring(lastHyphenIndex + 1);

  return [part1, part2, part3];
}


export default ItemPage;
