/** @format */

import React from 'react';
import {useHistory} from 'react-router-dom';
// Custom components
import Benefits from './benefits';
import HeroComp from './hero';
import PremiumFooter from './footer';
import PriceComp from './price';
import TextComp from './text';
// Material UI
import {styled} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import { Typography } from '@material-ui/core';

//#region Styled Comps
const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    backgroundColor:'black'
  },
  {name: 'membership-wrap'}
);
//#endregion

const SUBSCRIPTION_CHECKOUT_LINK = '/subscription';


export default function PremiumComp(props) {
  const {userData} = props;
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();

  const goToSubscriptionOrRegister = () => {
    if(userData.loggedIn === true) {
      history.push(SUBSCRIPTION_CHECKOUT_LINK);
    } else {
      enqueueSnackbar('Please Login or create an account to proceede with Premium.', {variant: 'info'});
      history.push('/premium/modal/login');
    }
  }
  return (
    <Wrapper>
      <HeroComp onClick={goToSubscriptionOrRegister} />
      {/* <PriceComp onClick={goToSubscriptionOrRegister} /> */}
      {/* <Separator bgColor="linear-gradient(0deg, transparent 70%, #d8a530 120%)" />
      <Separator bgColor="linear-gradient(180deg,#3BDFD2 0%,#05745F 100%)" /> */}
      
      <Benefits />
      <PremiumFooter onClick={goToSubscriptionOrRegister} />
      <TextComp />
    </Wrapper>
  );
}
