/** @format */

import React from 'react';
// Custom components
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const TextWrapper = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    // marginTop: '20vh',
    marginBottom:'10vh',
    padding: '0 10vw',
    width: '100%',
    backGroundColor:'black',
  },
  {name: 'txt-wrap'}
);

const TitleText = styled(Typography)(
  {
    color: '#fff',
    marginBottom:'3rem',
  },
  {name: 'title-txt'}
);

const Wrapper = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'min-content',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor:'black',
  },
  {name: 'benefits-wrap'}
);
//#endregion

export default function Benefits(props) {
  return (
    <Wrapper>
      <TextWrapper>
        <TitleText variant="h2">PrintBed Limited Edition PLA Spools</TitleText>
        <TitleText variant="body1">Members get 10% off our Limited Edition PLA spools. Limited Edition filament is available in unique colors and they’re not part of our standard collection or Premium collection in the filament subscription box. Limited Edition colors only run for a certain time and you can be one of the first people to have a unique PLA filament color and create one-of-a-kind 3D prints. Plus, you can choose the <Link to="/item/PLA%20Ltd?color=Mystery&weight=1kg&diameter=1.75mm&colorName=Mystery&showOnHome=true">mystery color spool</Link> and be the first to try some of our R&D spools.</TitleText>
        <TitleText variant="h2">Design Anything You Can Imagine with PrintBed</TitleText>
        <TitleText variant="body1">PrintBed is committed to supplying the highest quality <Link to="/">3D filament</Link>, ensuring your prints are always precise and accurate. Our dedication to customer service is unrivaled and you can contact us via phone, email, and social media platforms. With PrintBed, you can discover new and exciting colors in your filament subscription box to inspire creative ideas in your designs. Follow us on <a href="https://www.facebook.com/printbed/" target="_blank">Facebook</a> or <a href="https://www.youtube.com/channel/UCBsoiOZE9kGJU6W5E6SxBDA" target="_blank">YouTube</a> and tag us when you demonstrate your innovative designs!</TitleText>
      </TextWrapper>
    </Wrapper>
  );
}
