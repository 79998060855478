/** @format */

import React, {useEffect, useState} from 'react';
// npm Packages
import {useHistory, useLocation, useParams} from 'react-router-dom';
// Material UI Components
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// Material UI Icons
import SearchIcon from '@material-ui/icons/Search';
import {styled} from '@material-ui/core/styles';
// Custom Component Imports
// import Filter from './filters';
import FilterModal from './filterModal';
import SearchResults from './searchResults';
import AnimateHeight from '../animateheight';
import { BottomCopy, Headers, TopCopy } from './copy';
const API_TAG_BLACKLIST = ['new', 'sale', 'showOnHome'];
const UI_TAG_BLACKLIST = ['color'];

//#region Styled Components

const ContentHead = styled('div')(
  {
    alignItems: 'center',
    backgroundColor: '#fafafa',
    display: 'flex',
    flexDirection: 'row',
    height: '7.5rem',
  },
  {name: 'content-head'}
);

const MainContent = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    padding: '2vh 15vw 0',
    '& .divider': {
      width: '2px',
      borderRadius: '4px',
    },
  },
  {name: 'main-content'}
);

const Spacer = styled('div')(
  {
    height:'8rem'
  },
  {name: 'Spacer'}
);


const SearchBar = styled(TextField)({marginLeft:'3em'}, {name: 'search-bar'});

const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  {name: 'shop-wrap'}
);

//#endregion

export class FilterObj {
  title = '';
  vals = [];

  constructor(filterTitle, filterVals = []) {
    this.title = filterTitle;
    filterVals.length > 1
      ? (this.vals = this.vals.concat(filterVals))
      : this.vals.push(filterVals[0]);
  }

  addVal(newVal) {
    this.vals.push(newVal);
  }

  removeVal(valName) {
    this.vals.splice(this.vals.indexOf(valName), 1);
  }
}

function parseQuery(search) {
  const query = new URLSearchParams(search);
  let queryFilters = [];

  for (const key of query.keys()) {
    let sanitizedKey = key;
    if (key.startsWith('tags.')) {
      sanitizedKey = key.split('.')[1];
    }
    queryFilters.push(
      new FilterObj(sanitizedKey, query.getAll(key)[0].split(','))
    );
  }
  return queryFilters;
}

const store ={};

export default function Shop(props) {
  const {userData} = props;
  const {membership} = userData;
  const {category} = useParams();
  const hist = useHistory();
  const loc = useLocation();
  const [filters, setFilters] = useState([]);
  const [filterTags, setFilterTags] = useState({});
  const [queryStr, setQueryStr] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

 
  console.log(category)

  const EXTRA_TAGS = encodeURIComponent('&nsg=false&tags.showOnHome="true"');
  // Init
  useEffect(() => {
    if(navigator.userAgent === "ReactSnap") {
      return;
    }

    fetch(
      '/api/items/tags/all?filter=color,colorName,weight,material,diameter&filterInclusion=true'
    )
      .then(resp => resp.json())
      .then(data => {
        let dataCopy = data;
        let usableTagsObj = {};
        for (const tagName in dataCopy) {
          if (!API_TAG_BLACKLIST.includes(tagName)) {
            usableTagsObj[tagName] = dataCopy[tagName];
          }
        }
        setFilterTags(usableTagsObj);

        const tempFilters = [];
        for (const tagName in usableTagsObj) {
          let newFilterObj = new FilterObj(tagName);
          for (const val of usableTagsObj[tagName]) {
            newFilterObj.addVal(val);
          }
          tempFilters.push(newFilterObj);
        }
        setFilters(tempFilters);
        setSelectedFilters(parseQuery(loc.search));
        let queryString = loc.search.slice(1);
        if(queryString.indexOf('gclid')>=0) {
          hist.replace('/shop');
          queryString = '';
        }
        handleDialogClose(queryString);
      });
  }, []);

  // Updated selected filters
  useEffect(() => {
    updateSelectedFilters(queryStr);
  }, [queryStr,membership,category]);

  // Slightly modified version from here:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent
  function containsEncodedComponents(x) {
    // ie ?,=,&,/ etc
    return x !== decodeURIComponent(x);
  }

  function handleDialogClose(passedQueryStr) {
    if(category) {
      let string = getCategory(category);
      let materialPattern = /material=[^&]*/;
      if (materialPattern.test(passedQueryStr)) {
        passedQueryStr.replace(/(?<=material=)[^&]+/, string);
      } else {
        passedQueryStr += '&tags.material='+string;
      }
    }
    const queryIsEncoded = containsEncodedComponents(passedQueryStr);
    fetch(
      `/api/items/tags?q=${
        (queryIsEncoded ? passedQueryStr : encodeURIComponent(passedQueryStr)) +
        EXTRA_TAGS
      }`
    )
      .then(resp => resp.json())
      .then(data => {
        data.sort((a,b)=>(a.displayIndex < b.displayIndex) ? 1 : -1);
        data.reverse();
        let tmp = data.filter(item=>{
          if(item.tags.material === 'PLA Sub' && item.active !== true) {
            return true
          } else if(item.tags.material !== 'PLA Sub') {
            return true;
          } else {
            return false
          }
          
        })

        setSearchResults(tmp);
        if (loc.search.slice(1) !== passedQueryStr && !category) {
          updateSelectedFilters(passedQueryStr);
          hist.replace({
            pathname: loc.pathname,
            search: passedQueryStr,
          });
        }
      });
  }

  const getCategory = cat => {
    switch(cat) {
      case 'merch': 
        return 'tshirt,Poster';
      case 'upgrades':
        return 'nozzles,Tubing,parts';
      default:
        return cat;
    }
  }

  function updateSelectedFilters(passedQuery) {
    setSelectedFilters(parseQuery(passedQuery));
  }

  const filterResults = (e) => {
    const str = e.target.value;
    if(!store.original) {
      store.original = [...searchResults];
    }
    if(str === '') {
      return setSearchResults(store.original);
    }
    let tmp = [...store.original].filter(item=>{
      return(item.name.toLowerCase().indexOf(str.toLowerCase()) >=0 )
    });
    setSearchResults(tmp);
  }

  return (
    <Wrapper>
      <Headers category={category} />
      <ContentHead>
        <SearchBar
          InputProps={{
            startAdornment: <SearchIcon htmlColor="#A4AAB8" />,
          }}
          variant="outlined"
          placeholder="Search all products"
          id="search"
          size="small"
          autoFocus={true}
          onChange={filterResults}
        />
        <FilterModal
          initSelectedFilters={selectedFilters}
          onClose={handleDialogClose}
          tags={filterTags}
          uiTagBlacklist={UI_TAG_BLACKLIST}
        />
      </ContentHead>
      <MainContent>
        <TopCopy category={category} /> 
        <AnimateHeight>
          <SearchResults  itemArray={searchResults} />
        </AnimateHeight>
        <Spacer />
        <BottomCopy category={category}/>    
      </MainContent>
    </Wrapper>
  );
}
